// src/utils/realtimeDatabase.js
import { ref, onValue, set } from 'firebase/database';
import { db } from '../firebase';

export const saveColor = async (userId, colorName, colorCode) => {
  try {
    const colorRef = ref(db, `users/${userId}/colors/${colorCode}`);
    await set(colorRef, { name: colorName, code: colorCode });
  } catch (error) {
    console.error('Error saving color: ', error);
  }
};

export const getColors = (userId, callback) => {
  const colorsRef = ref(db, `users/${userId}/colors`);
  onValue(colorsRef, (snapshot) => {
    const data = snapshot.val();
    callback(data);
  });
};
