// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthPage from './components/AuthPage';
import MainApp from './components/MainApp';

const App = () => {
  const [user, setUser] = useState(null);
  console.log('1');
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<AuthPage setUser={setUser} />} />
        <Route path="/app" element={<MainApp user={user} />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
