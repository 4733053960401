import React, { useEffect, useRef, useState } from 'react';
import './ColorPickerPopup.css';

const ColorPickerPopup = ({ selectedColor, onClose, onComplete, onImageUpload, image, markerPosition, onCanvasClick, isIdealColorPopup }) => {
  const [name, setName] = useState('');
  const canvasRef = useRef(null);
  const handleClick = () => {
    // hidden input をクリックさせるための処理
    document.getElementById('file-upload-input').click();
  };

  useEffect(() => {
    if (image && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
      img.src = image;
    }
  }, [image]);

  const handleCanvasClick = (e) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const imageData = ctx.getImageData(x, y, 1, 1).data;
    const color = `rgb(${imageData[0]}, ${imageData[1]}, ${imageData[2]})`;
    onCanvasClick(color, { x, y });
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleComplete = () => {
    onComplete(name, selectedColor);
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
      <div className="container"
  style={{
    marginTop: '0px',
    display: 'flex', // フレックスボックスを使用
    justifyContent: 'space-between', // 左右に要素を配置
    alignItems: 'center', // 垂直方向の中央揃え
    marginBottom: '301.6px',
    width: '341.6px',
  }}
>
  <button className="blue-button" onClick={onClose}
    style={{
      width: '40px',
      height: '40px',
      backgroundColor: '#E4E4E5',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center', // 中央寄せ
      padding: '0',
      cursor: 'pointer',
    }}
  >
    <span className="material-symbols-outlined" style={{ fontSize: '24px', color: '#7F7F84' }}>close</span>
  </button>

  <div
    style={{
      width: '180px',
      height: '42px',
      backgroundColor: '#FFFFFF',
      borderRadius: '21px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center', // 中央寄せ
      padding: '0 10px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
    }}
    onClick={handleClick}
    >
    <div
      style={{
        width: '35px',
        height: '35px',
        backgroundColor: '#FF5252',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
    <span className="material-symbols-outlined" style={{ fontSize: '24px', color: '#fff' }}>upload</span>
    </div>
    <span
      style={{
        marginLeft: '10px',
        fontSize: '14px',
        fontWeight: 'normal',
        color: '#000',
      }}
    >
      画像をアップロード
    </span>

    {/* Hidden input element */}
    <input
      id="file-upload-input"
      type="file"
      accept="image/*"
      onChange={onImageUpload}
      style={{ display: 'none' }}  // 非表示にする
    />
  </div>
</div>

    {image && (
  <div
    style={{
      display: 'flex', // 横方向で中央寄せ
      justifyContent: 'center', // 水平方向の中央寄せ
      alignItems: 'center', // 垂直方向の中央寄せ
      marginTop: '-291.6px',
    }}
  >
    <div style={{ position: 'relative' }}>
      <canvas
        ref={canvasRef}
        width={300}
        height={300}
        onClick={handleCanvasClick}
        style={{ border: '1px solid black' }}
      />
      {markerPosition && (
        <div
          style={{
            position: 'absolute',
            top: markerPosition.y - 5,
            left: markerPosition.x - 5,
            width: '10px',
            height: '10px',
            backgroundColor: 'red',
            borderRadius: '50%',
            border: '2px solid white',
          }}
        ></div>
      )}
    </div>
  </div>
)}

        <div
        style={{
            display: 'flex', // 横並びにする設定
            justifyContent: 'center', // 横方向の中央揃え
            alignItems: 'center', // 縦方向の中央揃え
            margin: 'auto', // 自動で周囲に余白を付ける（任意）
        }}
        >
        <div
            style={{
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            backgroundColor: selectedColor || 'rgb(255, 255, 255)', // selectedColorがない時は白
            border: '1px solid #B1B1B4',
            }}
        ></div>
        <p style={{ marginLeft: '10px' }}>
            {selectedColor || 'rgb(255, 255, 255)'}  {/* selectedColorがない時はrgb(255, 255, 255)を表示 */}
        </p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {/* isIdealColorPopupがfalseのときのみ表示 */}
        {!isIdealColorPopup && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <input
              type="text"
              value={name}
              onChange={handleNameChange}
              style={{
                width: '260px',
                padding: '10px 15px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '25px',
                outline: 'none',
                transition: 'all 0.3s ease',
              }}
              placeholder="名前を入力してください"
              onFocus={(e) => (e.target.style.border = '1px solid #007BFF')}
              onBlur={(e) => (e.target.style.border = '1px solid #ccc')}
            />
          </div>
        )}
        <button
            onClick={handleComplete}
            style={{
            padding: '8px 20px', // ボタン内の余白
            fontSize: '16px',
            backgroundColor: '#007BFF', // ボタンの色
            color: '#fff', // テキストの色
            border: 'none', // ボーダーなし
            borderRadius: '25px', // 丸みのあるデザイン
            cursor: 'pointer', // ホバー時にカーソルを変える
            transition: 'background-color 0.3s ease', // 背景色のトランジション
            margin: 'auto',
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')} // ホバー時に色を濃く
            onMouseLeave={(e) => (e.target.style.backgroundColor = '#007BFF')} // ホバーアウト時に元の色に
        >
        完了
    </button>
</div>

      </div>
    </div>
  );
};

export default ColorPickerPopup;
