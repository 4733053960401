// utils/colorUtils.js
export const mixColors = (colors, weights) => {
    const totalWeight = weights.reduce((sum, weight) => sum + weight, 0);
    const mixedColor = colors.reduce(
      (acc, color, index) => {
        const weight = weights[index];
        acc.r += (color.r * weight) / totalWeight;
        acc.g += (color.g * weight) / totalWeight;
        acc.b += (color.b * weight) / totalWeight;
        return acc;
      },
      { r: 0, g: 0, b: 0 }
    );
    return { r: Math.round(mixedColor.r), g: Math.round(mixedColor.g), b: Math.round(mixedColor.b) };
};

export const colorDifference = (color1, color2) => {
    const rDiff = color1.r - color2.r;
    const gDiff = color1.g - color2.g;
    const bDiff = color1.b - color2.b;
    return Math.sqrt(rDiff * rDiff + gDiff * gDiff + bDiff * bDiff);
};

export const calculateRecreationScore = (idealColor, mixedColor) => {
    const maxDistance = Math.sqrt(3 * 255 * 255); // 最大の色差

    const distance = colorDifference(mixedColor, idealColor);

    // 距離が小さいほどスコアが高くなるように変換
    const score = 1 - (distance / maxDistance);

    return score * 100; // スコアをパーセンテージで返す
};

export const findBestColorCombination = (registeredColors, idealColor) => {
    if (!Array.isArray(registeredColors) || registeredColors.length === 0) {
        throw new Error('Invalid registeredColors: expected a non-empty array.');
    }

    const populationSize = 50;
    const generations = 100;
    const mutationRate = 0.1;

    const createIndividual = () => {
        const weights = Array(registeredColors.length).fill(0).map(() => Math.random());
        const totalWeight = weights.reduce((sum, weight) => sum + weight, 0);
        return weights.map(weight => weight / totalWeight);
    };

    const createPopulation = () => Array(populationSize).fill(0).map(createIndividual);

    const evaluateFitness = (individual) => {
        const mixedColor = mixColors(registeredColors, individual);
        return 1 / (1 + colorDifference(mixedColor, idealColor));
    };

    const selectParents = (population, fitnesses) => {
        const totalFitness = fitnesses.reduce((sum, fitness) => sum + fitness, 0);
        const probabilities = fitnesses.map(fitness => fitness / totalFitness);
        const cumulativeProbabilities = probabilities.reduce((acc, prob) => {
            if (acc.length === 0) return [prob];
            acc.push(acc[acc.length - 1] + prob);
            return acc;
        }, []);

        const selectOne = () => {
            const rand = Math.random();
            return population.find((_, index) => rand < cumulativeProbabilities[index]);
        };

        return [selectOne(), selectOne()];
    };

    const crossover = (parent1, parent2) => {
        const crossoverPoint = Math.floor(Math.random() * parent1.length);
        return [
            [...parent1.slice(0, crossoverPoint), ...parent2.slice(crossoverPoint)],
            [...parent2.slice(0, crossoverPoint), ...parent1.slice(crossoverPoint)]
        ];
    };

    const mutate = (individual) => {
        const mutated = individual.map(weight => (Math.random() < mutationRate ? Math.random() : weight));
        const totalWeight = mutated.reduce((sum, weight) => sum + weight, 0);
        return mutated.map(weight => weight / totalWeight);
    };

    let population = createPopulation();
    let bestIndividual = population[0];
    let bestFitness = evaluateFitness(bestIndividual);

    for (let generation = 0; generation < generations; generation++) {
        const fitnesses = population.map(evaluateFitness);
        const newPopulation = [];
        for (let i = 0; i < populationSize / 2; i++) {
            const [parent1, parent2] = selectParents(population, fitnesses);
            const [child1, child2] = crossover(parent1, parent2);
            newPopulation.push(mutate(child1), mutate(child2));
        }
        population = newPopulation;

        population.forEach(individual => {
            const fitness = evaluateFitness(individual);
            if (fitness > bestFitness) {
                bestFitness = fitness;
                bestIndividual = individual;
            }
        });
    }

    const bestCombination = {
        colors: registeredColors,
        weights: bestIndividual,
        score: calculateRecreationScore(
            idealColor,
            mixColors(registeredColors, bestIndividual) // スコアを計算
        )
    };

    const finalMixedColor = mixColors(registeredColors, bestIndividual);
    console.log(`Final Mixed Color: rgb(${finalMixedColor.r}, ${finalMixedColor.g}, ${finalMixedColor.b})`);

    return bestCombination;
};
