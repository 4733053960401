// AuthPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import './AuthPage.css';
const AuthPage = ({ setUser }) => {
  console.log('2');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegister, setIsRegister] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let userCredential;
      if (isRegister) {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
        alert('ユーザー登録が成功しました');
      } else {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
        alert('ログインが成功しました');
      }
      const user = userCredential.user;
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user)); // ローカルストレージに保存
      navigate('/app');
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="login-container">
      {/* Icon on the left */}
      <div style={{textAlign: 'center', marginTop: '30px',marginBottom: '30px'}}>
        <img src={`${process.env.PUBLIC_URL}/images/icon.png`} alt="Icon" style={{ width: '300px', height: 'auto' }} />
      </div>
      <div className="form-box">
        <h2>{isRegister ? 'アカウントを作成する' : 'ログイン'}</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Address"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <button type="submit">{isRegister ? 'アカウントを作成する' : 'ログイン'}</button>
        </form>
        <button className="switch-button" onClick={() => setIsRegister(!isRegister)}>
        {isRegister ? 'Already have an account? Sign In' : 'Create a new account'}
        </button>
      </div>
    </div>
  );
};

export default AuthPage;
