import React, { useState } from 'react';

const RGBInputPopup = ({ selectedColor, onClose, onComplete }) => {
  const [r, setR] = useState('');
  const [g, setG] = useState('');
  const [b, setB] = useState('');

  const handleComplete = () => {
    const rgbValue = `rgb(${r}, ${g}, ${b})`;
    console.log(rgbValue);
    onComplete(rgbValue);
  };
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="container"
          style={{
            marginTop: '0px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
            width: '300px',
          }}>
          <button className="blue-button" onClick={onClose}
            style={{
              width: '40px',
              height: '40px',
              backgroundColor: '#E4E4E5',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0',
              cursor: 'pointer',
            }}>
            <span className="material-symbols-outlined" style={{ fontSize: '24px', color: '#7F7F84' }}>close</span>
          </button>

          <div
            style={{
              width: '180px',
              height: '42px',
              backgroundColor: '#FFFFFF',
              borderRadius: '21px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0 10px',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            }}>
            <span style={{ marginLeft: '10px', fontSize: '14px', fontWeight: 'normal', color: '#000' }}>
              RGB入力
            </span>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
          <div style={{ textAlign: 'center' }}>
            <label>R </label>
            <input
              type="number"
              value={r}
              onChange={(e) => setR(e.target.value)}
              style={inputStyle}
              placeholder="0-255"
              max="255"
              min="0"
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <label>G </label>
            <input
              type="number"
              value={g}
              onChange={(e) => setG(e.target.value)}
              style={inputStyle}
              placeholder="0-255"
              max="255"
              min="0"
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <label>B </label>
            <input
              type="number"
              value={b}
              onChange={(e) => setB(e.target.value)}
              style={inputStyle}
              placeholder="0-255"
              max="255"
              min="0"
            />
          </div>
        </div>

        <button
          onClick={handleComplete}
          style={{
            padding: '8px 20px',
            fontSize: '16px',
            backgroundColor: '#007BFF',
            color: '#fff',
            border: 'none',
            borderRadius: '25px',
            cursor: 'pointer',
            margin: '20px auto',
            display: 'block',
            transition: 'background-color 0.3s ease',
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#007BFF')}
        >
          完了
        </button>
      </div>
    </div>
  );
};

const inputStyle = {
  width: '50px',
  height: '30px',
  padding: '5px',
  textAlign: 'center',
  borderRadius: '5px',
  border: '1px solid #ccc',
};

export default RGBInputPopup;
