import React, { useRef, useState, useEffect } from 'react';
import ColorPickerPopup from './ColorPickerPopup';
import { saveColor, getColors } from '../utils/realtimeDatabase';
import { mixColors, colorDifference, findBestColorCombination } from '../utils/colorUtils';
import RGBInputPopup from './RGBInputPopup';
import './MainApp.css';

const iconStyle = {
    fontVariationSettings: "'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 20",
    color: '#FFFFFF',
};

// 補色を計算する関数
const getComplementaryColor = (color) => {
  const r = 255 - color.r;
  const g = 255 - color.g;
  const b = 255 - color.b;
  return `rgb(${r}, ${g}, ${b})`;
};

const PieChart = ({ data, colors, score }) => {
    const canvasRef = useRef(null);
  
    useEffect(() => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
  
      // データを大きい順にソート
      const sortedData = data
        .map((value, index) => ({ value, color: colors[index] }))
        .sort((a, b) => b.value - a.value); // 降順にソート
  
      const total = sortedData.reduce((acc, item) => acc + item.value, 0);
      let startAngle = -Math.PI / 2; // 真上（0度）から開始
  
      // キャンバスのサイズを定義
      const radius = Math.min(canvas.width, canvas.height) / 2;
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
  
      // ドーナツ型の外側円と内側円の半径
      const outerRadius = radius;
      const innerRadius = radius * 0.5; // 内側の円の半径
  
      // 背景をクリア
      ctx.clearRect(0, 0, canvas.width, canvas.height);
  
      // データのセグメントを描画
      sortedData.forEach((item) => {
        const sliceAngle = (item.value / total) * 2 * Math.PI;
  
        // セグメントを描画
        ctx.beginPath();
        ctx.arc(centerX, centerY, outerRadius, startAngle, startAngle + sliceAngle);
        ctx.arc(centerX, centerY, innerRadius, startAngle + sliceAngle, startAngle, true);
        ctx.closePath();
  
        // セグメントの色を設定
        ctx.fillStyle = `rgb(${item.color.r}, ${item.color.g}, ${item.color.b})`;
        ctx.fill();
  
        // テキストの位置を計算
        const textAngle = startAngle + sliceAngle / 2;
        const textX = centerX + (outerRadius * 0.6) * Math.cos(textAngle);
        const textY = centerY + (outerRadius * 0.6) * Math.sin(textAngle);
  
        // 補色をフォントカラーに設定
        ctx.fillStyle = getComplementaryColor(item.color);
        ctx.font = 'bold 16px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(`${(item.value * 100).toFixed(2)}%`, textX, textY);
  
        // 次のセグメントの開始角度を設定
        startAngle += sliceAngle;
      });
  
      // 中央にテキストを追加
      ctx.fillStyle = 'black';
      ctx.font = 'bold 20px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('再現度', centerX, centerY - 10);
      ctx.font = 'bold 24px Arial';
      ctx.fillText(`${Math.round(score * 10) / 10}%`, centerX, centerY + 20);
    }, [data, colors, score]);
  
    return <canvas ref={canvasRef} width={300} height={300} />;
  };
  
  

    
  

const ImageColorPicker = ({ onColorSelect }) => {
    const [isRGBPopupOpen, setIsRGBPopupOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedName, setSelectedName] = useState('');
    const [markerPosition, setMarkerPosition] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isIdealColorPopupOpen, setIsIdealColorPopupOpen] = useState(false);
    const [colors, setColors] = useState({});
    const [idealColor, setIdealColor] = useState(null);
    const [bestCombination, setBestCombination] = useState(null);
    const canvasRef = useRef(null);
    const [showPieChart, setShowPieChart] = useState(false);
    const [editColor, setEditColor] = useState(null);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);


    const toggleView = () => {
        setShowPieChart(!showPieChart); // true/false を切り替え
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
        setImage(event.target.result);
        };
        reader.readAsDataURL(file);
    };

    useEffect(() => {
        if (image && canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.onload = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        };
        img.src = image;
        }
    }, [image]);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
        const user = JSON.parse(storedUser);
        getColors(user.uid, (data) => {
            setColors(data || {});
        });
        }
    }, []);

    const handleCanvasClick = (color, position) => {
        setSelectedColor(color);
        setMarkerPosition(position);
    };

    const handleComplete = async (name, color, isIdealColor = false) => {
        try {
        setSelectedName(name);
        setSelectedColor(color);
        setIsPopupOpen(false);
        setIsIdealColorPopupOpen(false);
        setIsRGBPopupOpen(false);

        if (isIdealColor) {
            setIdealColor(color);
            console.log('理想の色が設定されました:', color);

            const registeredColors = Object.keys(colors).map(colorString => {
            console.log(colors[colorString].name);
            const match = colorString.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
            if (match) {
                return {
                r: parseInt(match[1], 10),
                g: parseInt(match[2], 10),
                b: parseInt(match[3], 10),
                name: colors[colorString].name ? colors[colorString].name : 'Unknow',
                };
            }
            return null;
            }).filter(color => color !== null);

            const idealmatch = color.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);

            const idealColorRGB = {
            r: parseInt(idealmatch[1], 10),
            g: parseInt(idealmatch[2], 10),
            b: parseInt(idealmatch[3], 10),
            };

            console.log('理想の色:', idealColorRGB);
            console.log('登録された色:', registeredColors);
            const combination = findBestColorCombination(registeredColors, idealColorRGB);

            console.log('最適な組み合わせ:', combination);
            setBestCombination(combination);
        } else {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
            const user = JSON.parse(storedUser);
            await saveColor(user.uid, name, color);
            console.log('色が保存されました:', color);
            }
        }
        } catch (error) {
        console.error('エラーが発生しました:', error);
        }
    };

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const handleOpenIdealColorPopup = () => {
        setIsIdealColorPopupOpen(true);
    };

    const handleCloseIdealColorPopup = () => {
        setIsIdealColorPopupOpen(false);
    };
    

    const handleOpenRGBPopup = () => {
        setIsRGBPopupOpen(true); // RGB入力ポップアップを開く
    };

    const handleCloseRGBPopup = () => {
        setIsRGBPopupOpen(false); // RGB入力ポップアップを閉じる
    };

    return (
    <div
    style={{
        width: '100%',
    }}>      
    {/* Header starts here */}
    <header style={{ 
      alignItems: 'center', 
      justifyContent: 'space-between', 
      padding: '10px', 
      width: '100%',
      backgroundColor: '#F9F9F9', 
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' 
    }}>
      
      {/* Icon on the left */}
      <div style={{ marginRight: '20px'}}>
        <img src={`${process.env.PUBLIC_URL}/images/icon.png`} alt="Icon" style={{ width: '200px', height: 'auto' }} />
      </div>

      {/* Color and Registration Buttons */}
      <div
        style={{
          display: 'flex',
          flexWrap: 'nowrap', // Ensures items stay in a single line
          overflowX: 'auto', // Enables horizontal scrolling
        }}
      >
        <div style={{ margin: '5px', textAlign: 'center' }} onClick={handleOpenPopup}>
          <p style={{ 
            margin: '0px', 
            color: '#3C3C43', 
            fontWeight: 'bold', 
            fontSize: '11pt', 
          }}>
            登録
          </p>
          <div
            style={{
              width: '40px',
              height: '40px',
              backgroundColor: '#E4E4E5',
              borderRadius: '50%',
              flexShrink: 0, // Prevents shrinking
              position: 'relative', // Needed for absolute positioning of the icon
            }}
          >
            <span
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '30px',
                color: '#3C3C43', // Adjust color as needed
                opacity: 0.6,
              }}
            >
              +
            </span>
          </div>
        </div>

        {/* List of colors */}
        {Object.keys(colors).map((colorCode) => (
          <div key={colorCode} style={{ margin: '5px', textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>
              {colors[colorCode].name 
                ? colors[colorCode].name.length > 3 
                  ? `${colors[colorCode].name.substring(0, 2)}...`
                  : colors[colorCode].name
                : '\u00A0'}
            </p>
            <div
              style={{
                width: '40px',
                height: '40px',
                backgroundColor: colorCode,
                border: '1px solid #B1B1B4',
                borderRadius: '50%',
                flexShrink: 0, // Prevents shrinking
              }}
            ></div>
          </div>
        ))}
      </div>
    </header>
    {/* Header ends here */}

        <div
            style={{
                width: '100%',
                height: '0.2px',
                backgroundColor: '#C9C9C9',
                marginTop : '10px',
                marginBottom: '10px',
                }}
        ></div>

        
    {/* Rest of the content */}
    <div className="form-box">
      <h1
        style={{
          fontSize: '24pt',
          fontWeight: 'bold',
          color: '#000',
          marginBottom: '20px',
        }}
      >
            {bestCombination ? "実行終了" : "CREATE COLOR"}
        </h1>

        {/* Conditionally render based on bestCombination */}
        {!bestCombination ? (
            // Original content before bestCombination is found
            <>
            <div
                style={{
                margin: 'auto',
                width: '180px',
                height: '42px',
                backgroundColor: '#FFFFFF',
                borderRadius: '21px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: '0 10px',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                }}
                onClick={handleOpenIdealColorPopup}
            >
                <div
                style={{
                    width: '35px',
                    height: '35px',
                    backgroundColor: '#3BA1FF',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                >
                <span class="material-symbols-outlined" style={iconStyle}>add_photo_alternate</span>
                </div>
                <span
                style={{
                    marginLeft: '10px',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    color: '#000',
                }}
                >
                +画像から抽出
                </span>
            </div>
            <div
                style={{
                margin: 'auto',
                marginTop: '10px',
                marginBottom: '10px',
                width: '180px',
                height: '42px',
                backgroundColor: '#FFFFFF',
                borderRadius: '21px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: '0 10px',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                }}
                onClick={handleOpenRGBPopup}
            >
                <div
                style={{
                    width: '35px',
                    height: '35px',
                    backgroundColor: '#3BA1FF',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                >
                <span class="material-symbols-outlined" style={iconStyle}>input</span>
                </div>
                <span
                style={{
                    marginLeft: '10px',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    color: '#000',
                }}
                >
                +直接打ち込む
                </span>
            </div>
            </>
        ) : (
            <div>
        <div
        style={{
            display: 'flex',
            justifyContent: 'center', // 横方向中央揃え
            alignItems: 'center', // 縦方向中央揃え（必要であれば）
            height: 'auto', // コンテナ全体の高さを画面いっぱいにする
        }}
        >
        <button
            onClick={toggleView}
            style={{
            marginBottom: '20px',
            padding: '5px 10px',
            fontSize: '14px',
            fontWeight: 'bold',
            borderRadius: '10px',
            background: showPieChart ? 'linear-gradient(90deg, #4CAF50, #81C784)' : 'linear-gradient(90deg, #FF5722, #FF7043)',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            transition: 'background 0.3s ease, transform 0.2s ease',
            transform: showPieChart ? 'scale(1.1)' : 'scale(1)',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            }}
            onMouseDown={(e) => {
            e.currentTarget.style.transform = 'scale(0.95)';
            }}
            onMouseUp={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            }}
            onMouseEnter={(e) => {
            e.currentTarget.style.boxShadow = '0px 8px 12px rgba(0, 0, 0, 0.2)';
            }}
            onMouseLeave={(e) => {
            e.currentTarget.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
            }}
        >
            {showPieChart ? '割合を表示' : '円グラフを表示'}
        </button>
        </div>
                    {showPieChart ? (
                <div
                style={{
                    marginBottom: '15px',
                }}
                >
                <PieChart 
                    data={bestCombination.weights} 
                    colors={bestCombination.colors}
                    score={bestCombination.score}
                />
                </div>
            ) : (
                <div
                style={{
                    marginBottom: '15px',
                }}
                >
                {bestCombination.weights
        .map((weight, index) => ({ weight, color: bestCombination.colors[index] }))
        .sort((a, b) => b.weight - a.weight) // 割合の高い順にソート
        .slice(0, 10) // 最初の5個だけ表示
        .map((item, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '0' }}>
            <span style={{ 
                marginRight: '10px', 
                width: '50px', // 固定幅を設定
                whiteSpace: 'nowrap', // テキストを一行に
                overflow: 'hidden', // はみ出した部分を隠す
                textOverflow: 'ellipsis' // 省略記号を表示
                }}>
                {typeof item.color === 'object' 
                ? item.color.name === 'unknown' 
                    ? '' 
                    : item.color.name.length > 4 
                    ? `${item.color.name.slice(0, 4)}...` 
                    : item.color.name 
                : item.color}
            </span>
            <div
                style={{
                width: '150px', // 棒グラフの固定幅
                height: '21px', // 棒の高さ
                backgroundColor: '#e0e0e0', // 灰色の背景
                borderRadius: '12.5px', // 丸みを帯びた角
                position: 'relative', // 色付きバーの位置調整のため
                overflow: 'hidden' // 子要素がはみ出さないようにする
                }}
            >
                <div
                style={{
                    width: `${item.weight * 100}%`, // 割合に応じて横幅を設定
                    height: '100%',
                    backgroundColor: typeof item.color === 'object' 
                    ? `rgb(${item.color.r}, ${item.color.g}, ${item.color.b})` 
                    : item.color, // 色を設定
                }}
                ></div>
            </div>
            <p style={{ margin: '5px 0', marginLeft: '10px' }}>
                {Math.round(item.weight * 100 * 10) / 10}%
            </p>
            </div>
        ))}
        </div>
      )}
            </div>
        )}
        </div>

    {isPopupOpen && ( 
        <ColorPickerPopup
        selectedColor={selectedColor}
        onClose={handleClosePopup}
        onComplete={(name, color) => handleComplete(name, color, false)}
        onImageUpload={handleImageUpload}
        image={image}
        markerPosition={markerPosition}
        onCanvasClick={handleCanvasClick}
        isIdealColorPopup={false} // 通常のポップアップ
        />
    )}
    {isIdealColorPopupOpen && (
        <ColorPickerPopup
        selectedColor={selectedColor}
        onClose={handleCloseIdealColorPopup}
        onComplete={(name, color) => handleComplete(name, color, true)}
        onImageUpload={handleImageUpload}
        image={image}
        markerPosition={markerPosition}
        onCanvasClick={handleCanvasClick}
        isIdealColorPopup={true} // 理想の色用ポップアップ
        />
    )}

    {isRGBPopupOpen && (
        <RGBInputPopup 
        selectedColor={selectedColor}
        onClose={handleCloseRGBPopup} 
        onComplete={(color) => handleComplete('', color, true)}
        />
    )} 
    </div>
    );
    };

    export default ImageColorPicker;
