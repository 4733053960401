// src/firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase } from 'firebase/database';
import { getAuth } from "firebase/auth"; // 追加

const firebaseConfig = {
  apiKey: "AIzaSyDGZdeZD6QuSyEBSuS-FDpEfHx7MX2Q_FQ",
  authDomain: "original-palette.firebaseapp.com",
  databaseURL: "https://original-palette-default-rtdb.firebaseio.com",
  projectId: "original-palette",
  storageBucket: "original-palette.appspot.com",
  messagingSenderId: "1098177100719",
  appId: "1:1098177100719:web:93221635f1358b96464d5a"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);

export { db, auth };
